import Link from "next/link";

export default function Custom404() {
  return (
    <section className="not-found">
      <div className="not-found__wrapper">
        <div className="not-found__number">404</div>
        <h1 className="not-found__title">
          Strona, której szukasz nie istnieje
        </h1>
        <Link href="/" className="not-found__link">
          Wróć na stronę główną
        </Link>
      </div>
    </section>
  );
}
